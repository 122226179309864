import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import {
  Cpu,
  Battery,
  Zap,
  Star,
  ArrowBigLeftDash,
  Phone,
  ChevronLeft,
  ChevronRight,
  X,
} from "lucide-react";
import Layout from "../../layout";
import { Button } from "../../../components/ui/Button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../components/ui/Card";
import { Img } from "../../../components/ui/Img";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../components/ui/Tabs";

interface Product {
  id: number;
  name: string;
  // price: number;
  category: string;
  description: string;
  // rating: number;
  icon: React.ElementType;
  images: string[];
  features: string[];
  specs: Record<string, string>;
}

const products: Product[] = [
  {
    id: 1,
    name: "Science Box no 1",
    // price: 599,
    category: "Aerial",
    description:
      "Science Box no 1 is an educational kit designed to introduce fundamental concepts of mechanics and electronics through hands-on learning.",
    // rating: 4.7,
    icon: Zap,
    images: [
      "/Images/RoboticAI/no1.jpg",
      "/Images/scienceBox/1.jpg",
      "/Images/scienceBox/201.png",
      "/Images/scienceBox/Sb 4.png",
    ],
    features: [
      "Interactive Learning Modules",
      "User-Friendly Instructions",
      "Versatile Project Applications",
      "Support for STEM Education",
    ],
    specs: {
      Components: "Gears, motors, wires, sensors",
      "Educational Alignment":
        "Aligns with national science education standards",
      "Age Group": "Recommended for ages 12 and up",
      "Safety Standards":
        "Complies with relevant safety standards for educational tools",
    },
  },
  {
    id: 2,
    name: "Science Box no 2",
    // price: 399,
    category: "Home",
    description:
      "An educational kit designed to introduce and deepen understanding of mechanical engineering through hands-on projects and experiments.",
    // rating: 4.5,
    icon: Cpu,
    images: [
      "/Images/RoboticAI/no2.jpg",
      "/Images/scienceBox/2.jpg",
      "/Images/scienceBox/202.png",
      "/Images/scienceBox/SB6.png",
    ],
    features: [
      "Mechanical assembly projects to build functional devices",
      "Educational content that aligns with STEM learning objectives",
      "Interactive experiments to understand mechanical operations",
      "Designed to enhance problem-solving and critical thinking skills",
    ],
    specs: {
      "Kit Contents": "Various mechanical parts and tools for assembly",
      "Instruction Quality": "Detailed, user-friendly manuals and guides",
      "Age Group":
        "Suitable for ages 12 and up, perfect for schools and hobbyists",
      "Safety Standards":
        "Meets international safety standards for educational kits",
    },
  },
  {
    id: 3,
    name: "Science box no 3",
    // price: 1299,
    category: "Industrial",
    description:
      "Designed to enhance understanding of advanced mechanical and engineering principles through hands-on projects.",
    // rating: 4.8,
    icon: Battery,
    images: [
      "/Images/RoboticAI/no3.png",
      "/Images/scienceBox/no3.png",
      "/Images/scienceBox/203.png",
      "/Images/scienceBox/product1.png",
    ],
    features: [
      "In-depth exploration of mechanical structures",
      "Projects focusing on energy conversion and power transfer",
      "Includes real-world applications of physics and engineering",
      "Designed to promote critical thinking and problem-solving skills",
    ],
    specs: {
      "Kit Contents": "Gears, levers, pulleys, and electronic components",
      "Educational Focus": "Mechanical engineering, physics",
      "Age Group": "Suitable for ages 14 and up",
      "Safety Standards":
        "Meets all applicable safety standards for educational kits",
    },
  },
];

const RoboticsAIProductPage: React.FC = () => {
  const navigate = useNavigate();
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleProductClick = (product: Product) => {
    setSelectedProduct(product);
    setCurrentImageIndex(0);
  };

  const handlePrevImage = () => {
    if (selectedProduct) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === 0 ? selectedProduct.images.length - 1 : prevIndex - 1
      );
    }
  };

  const handleNextImage = () => {
    if (selectedProduct) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === selectedProduct.images.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  return (
    <Layout>
      <motion.div
        className="container mx-auto px-4 py-8 bg-white-a700 text-gray-800 min-h-screen mt-20"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="flex flex-row justify-between md:flex-row md:justify-start md:gap-4 gap-2 mb-4">
          <Button
            className="md:mr-auto bg-gray-300 hover:bg-gray-400 text-black px-4 py-2 rounded-xl flex items-center justify-center"
            onClick={() => navigate(-1)}
          >
            <ArrowBigLeftDash size={20} className="mr-2" />
            Back
          </Button>
        </div>

        <motion.div
          className="grid grid-cols-1 md:grid-cols-3 gap-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          {products.map((product) => (
            <motion.div
              key={product.id}
              whileHover={{ scale: 1.05 }}
              className="overflow-hidden bg-white-a700 border border-gray-200 rounded-lg shadow cursor-pointer"
              onClick={() => handleProductClick(product)}
            >
              <Card className="h-full flex flex-col">
                <CardHeader className="bg-gradient-to-r from-blue-500 to-purple-500 text-white-a700">
                  <div className="flex justify-between items-center">
                    <CardTitle>{product.name}</CardTitle>
                    <product.icon />
                  </div>
                </CardHeader>
                <CardContent className="flex-grow">
                  <Img
                    src={product.images[0]}
                    alt={product.name}
                    referrerPolicy="no-referrer"
                    className="w-full h-48 object-contain rounded-lg mb-4"
                  />
                  <p className="text-gray-600 line-clamp-2">
                    {product.description}
                  </p>
                  {/* <div className="flex items-center mt-2">
                    {Array.from({ length: 5 }).map((_, index) => (
                      <Star
                        key={index}
                        className={`w-4 h-4 ${
                          index < Math.floor(product.rating)
                            ? "text-yellow-500 fill-current"
                            : "text-gray-400"
                        }`}
                      />
                    ))}
                    <span className="ml-2 text-sm text-gray-600">
                      {product.rating.toFixed(1)}
                    </span>
                  </div> */}
                </CardContent>
                {/* <CardFooter className="flex justify-between items-center">
                  <div className="bg-green-500 text-white-a700 px-4 py-2 rounded-full shadow-md">
                    <span className="font-bold text-lg">${product.price}</span>
                  </div>
                  <Button
                    className="bg-blue-500 hover:bg-blue-600 text-white-a700"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleProductClick(product);
                    }}
                  >
                    Details
                  </Button>
                </CardFooter> */}
              </Card>
            </motion.div>
          ))}
        </motion.div>

        <AnimatePresence>
          {selectedProduct && (
            <motion.div
              className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <motion.div
                className="bg-white-a700 p-8 rounded-lg max-w-3xl w-full max-h-[90vh] overflow-y-auto"
                initial={{ scale: 0.9 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0.9 }}
              >
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-3xl font-bold">{selectedProduct.name}</h2>
                  <Button
                    className="bg-gray-200 hover:bg-gray-300 text-gray-800 rounded-full p-2"
                    onClick={() => setSelectedProduct(null)}
                  >
                    <X size={24} />
                  </Button>
                </div>
                <div className="relative mb-4">
                  <Img
                    src={selectedProduct.images[currentImageIndex]}
                    alt={`${selectedProduct.name} - Image ${
                      currentImageIndex + 1
                    }`}
                    className="w-full h-64 object-contain rounded-lg"
                    referrerPolicy="no-referrer"
                  />
                  <Button
                    className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-white-a700 bg-opacity-50 hover:bg-opacity-75 text-black rounded-full p-2"
                    onClick={handlePrevImage}
                  >
                    <ChevronLeft size={24} />
                  </Button>
                  <Button
                    className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-white-a700 bg-opacity-50 hover:bg-opacity-75 text-black rounded-full p-2"
                    onClick={handleNextImage}
                  >
                    <ChevronRight size={24} />
                  </Button>
                </div>
                <div className="flex justify-center space-x-2 mb-4">
                  {selectedProduct.images.map((_, index) => (
                    <button
                      key={index}
                      className={`w-3 h-3 rounded-full ${
                        index === currentImageIndex
                          ? "bg-blue-500"
                          : "bg-gray-300"
                      }`}
                      onClick={() => setCurrentImageIndex(index)}
                    />
                  ))}
                </div>

                <p className="text-gray-600 mb-4">
                  {selectedProduct.description}
                </p>
                {/* <div className="bg-green-500 text-white-a700 px-4 py-2 rounded-full shadow-md inline-block mb-4">
                  <span className="font-bold text-lg">
                    ${selectedProduct.price}
                  </span>
                </div> */}

                <Tabs defaultValue="features" className="mt-6">
                  <TabsList className="grid w-full grid-cols-3">
                    <TabsTrigger value="features">Features</TabsTrigger>
                    <TabsTrigger value="specs">Specifications</TabsTrigger>
                    <TabsTrigger value="contact">Contact</TabsTrigger>
                  </TabsList>
                  <TabsContent value="features">
                    <Card>
                      <CardContent className="pt-6">
                        <ul className="list-disc pl-5 space-y-2">
                          {selectedProduct.features.map((feature, index) => (
                            <li key={index}>{feature}</li>
                          ))}
                        </ul>
                      </CardContent>
                    </Card>
                  </TabsContent>
                  <TabsContent value="specs">
                    <Card>
                      <CardContent className="pt-6">
                        <dl className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
                          {Object.entries(selectedProduct.specs).map(
                            ([key, value]) => (
                              <React.Fragment key={key}>
                                <dt className="font-semibold">{key}:</dt>
                                <dd>{value}</dd>
                              </React.Fragment>
                            )
                          )}
                        </dl>
                      </CardContent>
                    </Card>
                  </TabsContent>
                  <TabsContent value="contact">
                    <Card>
                      <CardContent className="pt-6">
                        <Button
                          className="w-full bg-blue-600 hover:bg-blue-700 text-white-a700"
                          onClick={() => navigate("/contact-us")}
                        >
                          <Phone className="mr-2 h-4 w-4" /> Contact for Inquiry
                        </Button>
                      </CardContent>
                    </Card>
                  </TabsContent>
                </Tabs>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </Layout>
  );
};

export default RoboticsAIProductPage;
