import React, { useEffect, useRef, useState } from "react";
import { motion, useInView, AnimatePresence } from "framer-motion";
import {
  Users,
  Award,
  CheckCircle,
  Plane,
  Bot,
  ChevronLeft,
  ChevronRight,
  TrendingUp,
} from "lucide-react";

import { Text } from "../../components/ui/Text";
import { Button } from "../../components/ui/Button";
import { Img } from "../../components/ui/Img";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "../../components/ui/Card";

import { useNavigate } from "react-router-dom";
import PartnersSection from "../PartnerSection";
import FAQSection from "../FandQSection";

// Animation Variants for each section
// const leftToCenterAnimation = {
//   hidden: { opacity: 0, x: -100 },
//   visible: { opacity: 1, x: 0, transition: { duration: 2, ease: "easeOut" } },
// };
// const rightToCenterAnimation = {
//   hidden: { opacity: 0, x: 100 },
//   visible: { opacity: 1, x: 0, transition: { duration: 2, ease: "easeOut" } },
// };
// // const statsPopInAnimation = {
//   hidden: { opacity: 0, scale: 0.5 },
//   visible: {
//     opacity: 1,
//     scale: 1,
//     transition: { duration: 1.5, ease: "easeOut", staggerChildren: 0.15 },
//   },
// };
// const productAnimation = {
//   hidden: { opacity: 0, y: 30 },
//   visible: {
//     opacity: 1,
//     y: 0,
//     transition: { duration: 2, ease: "easeOut", staggerChildren: 0.2 },
//   },
// };
const customerImages = [
  "./Images/landingPage/AboutUs.jpg",
  // "./Images/landingPage/about1.png",
  // "./Images/landingPage/about2.png",
  // "./Images/landingPage/about3.png",
  // "./Images/landingPage/about4.png",
  // "./Images/landingPage/about5.png",
  // "./Images/landingPage/about6.png",
];
// About Us Section
const AboutUsSection: React.FC = () => {
  const aboutRef = useRef(null);
  const isAboutInView = useInView(aboutRef, { once: true });
  const navigate = useNavigate();

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % customerImages.length
      );
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  return (
    <motion.section
      ref={aboutRef}
      initial="hidden"
      animate={isAboutInView ? "visible" : "hidden"}
      variants={containerVariants}
      className="py-20 bg-gradient-to-br from-blue-50 to-white"
    >
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <motion.div variants={itemVariants} className="space-y-6">
            <Text
              size="textxl"
              className="font-bold text-3xl sm:text-4xl md:text-5xl text-gray-900 leading-tight"
            >
              MetaSquare – Shaping the Future of Technology Education in Vietnam
            </Text>
            <Text
              size="textmd"
              className="text-gray-700 text-lg leading-relaxed"
            >
              At MetaSquare, we believe the future belongs to those who innovate
              and master technology. Founded with the mission to enhance
              technological skills and foster creativity among the younger
              generation, we proudly bridge students with cutting-edge
              advancements, preparing them for the challenges of tomorrow.
            </Text>
            <div className="flex space-x-4">
              <Button
                size="lg"
                className="bg-blue-600 text-white-a700 hover:bg-blue-700 transition duration-300 ease-in-out flex items-center"
                onClick={() => navigate("/about")}
              >
                Learn More
                <ChevronRight className="ml-2 h-5 w-5" />
              </Button>
              <Button
                size="lg"
                variant="outline"
                className="border-blue-600 text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out"
                onClick={() => navigate("/courses")}
              >
                Explore Courses
              </Button>
            </div>
          </motion.div>

          <motion.div variants={itemVariants} className="relative">
            <div className="relative w-full h-[380px] rounded-lg shadow-lg overflow-hidden">
              <AnimatePresence mode="wait">
                <motion.div
                  key={currentImageIndex}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                  className="absolute inset-0"
                >
                  <Img
                    src="/Images/LandingPage/AboutUs.jpg"
                    alt="Innovative learning environment"
                    className="object-cover"
                  />
                </motion.div>
              </AnimatePresence>
            </div>
          </motion.div>
        </div>
      </div>
    </motion.section>
  );
};
// Stats Section
// const StatsSection: React.FC = () => {
//   const statsRef = useRef(null);
//   const isStatsInView = useInView(statsRef, { once: true });

//   const stats = [
//     { number: "20K+", label: "Students Trained", icon: Users },
//     { number: "50+", label: "Tech Courses", icon: Award },
//     { number: "98%", label: "Job Placement Rate", icon: CheckCircle },
//     { number: "100%", label: "Industry-Aligned Curriculum", icon: TrendingUp },
//   ];

//   return (
//     <motion.section
//       ref={statsRef}
//       initial="hidden"
//       animate={isStatsInView ? "visible" : "hidden"}
//       variants={{
//         hidden: { opacity: 0, y: 50 },
//         visible: {
//           opacity: 1,
//           y: 0,
//           transition: { duration: 0.8, staggerChildren: 0.2 },
//         },
//       }}
//       className="py-24 "
//     >
//       <div className="container mx-auto px-4">
//         <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
//           {stats.map((stat, index) => (
//             <motion.div
//               key={index}
//               variants={{
//                 hidden: { opacity: 0, y: 20 },
//                 visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
//               }}
//               className="text-center p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300"
//             >
//               <stat.icon className="w-12 h-12 mx-auto mb-4 text-blue-600" />
//               <Text
//                 size="textxl"
//                 className="font-bold text-3xl mb-2 text-gray-800"
//               >
//                 {stat.number}
//               </Text>
//               <Text size="texts" className="text-gray-600">
//                 {stat.label}
//               </Text>
//             </motion.div>
//           ))}
//         </div>
//       </div>
//     </motion.section>
//   );
// };

// Program Showcase Section

const programData = {
  roboticAI: {
    title: "Robotic AI Program",
    description:
      "Explore the cutting-edge intersection of robotics and artificial intelligence.",
    descriptionImage: "/Images/logo/sb.png",
    images: [
      "/Images/LandingPage/no1.jpg",
      "/Images/LandingPage/no2.jpg",
      "/Images/LandingPage/no3.png",
    ],
    features: [
      "Advanced machine learning algorithms",
      "Robotic process automation",
      "Computer vision and image recognition",
      "Natural language processing for human-robot interaction",
    ],
  },
  drones: {
    title: "Drone Technology Program",
    description:
      "Master the art and science of unmanned aerial vehicles and their applications.",
    descriptionImage: "/Images/logo/Drone division.png",

    images: [
      "/Images/LandingPage/drone1.png",
      "/Images/LandingPage/drone2.png",
      "/Images/LandingPage/drone3.png",
    ],
    features: [
      "Autonomous flight systems",
      "Aerial photography and videography",
      "Drone mapping and surveying",
      "Search and rescue operations",
    ],
  },
};
const ProgramShowcase: React.FC = () => {
  const [activeProgram, setActiveProgram] = useState<"roboticAI" | "drones">(
    "roboticAI"
  );
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const setProgram = (program: "roboticAI" | "drones") => {
    setActiveProgram(program);
  };
  const nextImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex + 1) % programData[activeProgram].images.length
    );
  };

  const prevImage = () => {
    setCurrentImageIndex(
      (prevIndex) =>
        (prevIndex - 1 + programData[activeProgram].images.length) %
        programData[activeProgram].images.length
    );
  };
  // Ref and visibility tracking for each section element
  const buttonsRef = useRef(null);
  const buttonsInView = useInView(buttonsRef, { once: true });

  const cardTitleRef = useRef(null);
  const cardTitleInView = useInView(cardTitleRef, { once: true });

  const featuresRef = useRef(null);
  const featuresInView = useInView(featuresRef, { once: true });

  return (
    <motion.section
      className="py-20 "
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <div className="container mx-auto px-6 lg:px-8">
        <motion.h1
          className="text-4xl font-bold text-center mb-10 text-gray-800"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          Our Innovative Programs
        </motion.h1>

        {/* Program Selection Buttons */}
        <div ref={buttonsRef} className="flex flex-col items-center mb-12">
          <motion.div
            className="flex space-x-6 mb-8"
            initial={{ opacity: 0, y: 20 }}
            animate={buttonsInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            <Button
              onClick={() => setProgram("roboticAI")}
              variant={activeProgram === "roboticAI" ? "default" : "outline"}
              className={`${
                activeProgram === "roboticAI"
                  ? "bg-blue-600 text-white-a700 hover:bg-blue-700"
                  : "bg-transparent border-blue-600 text-blue-600 hover:bg-blue-100"
              }`}
            >
              Robotic AI
              <Bot className="ml-2 h-4 w-4" />
            </Button>
            <Button
              onClick={() => setProgram("drones")}
              variant={activeProgram === "drones" ? "default" : "outline"}
              className={`${
                activeProgram === "drones"
                  ? "bg-blue-600 text-white-a700 hover:bg-blue-700"
                  : "bg-transparent border-blue-600 text-blue-600 hover:bg-blue-100"
              }`}
            >
              Drones
              <Plane className="ml-2 h-4 w-4" />
            </Button>
          </motion.div>

          {/* Program Details */}
          <AnimatePresence mode="wait">
            <motion.div
              key={activeProgram}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.4 }}
              className="w-full max-w-5xl"
            >
              <Card className="p-8 bg-white-a700 shadow-lg rounded-lg border border-gray-200">
                <CardHeader>
                  <div className="flex flex-col lg:flex-row items-center justify-between gap-6 lg:gap-10">
                    <motion.div
                      className="flex-1"
                      ref={cardTitleRef}
                      initial={{ opacity: 0, x: -20 }}
                      animate={cardTitleInView ? { opacity: 1, x: 0 } : {}}
                      transition={{ duration: 0.5 }}
                    >
                      <CardTitle className="text-3xl mb-4 text-gray-800">
                        {programData[activeProgram].title}
                      </CardTitle>
                      <CardDescription className="text-lg text-gray-600">
                        {programData[activeProgram].description}
                      </CardDescription>
                    </motion.div>
                    <motion.img
                      src={programData[activeProgram].descriptionImage}
                      alt={`${programData[activeProgram].title} illustration`}
                      className="w-1/4 lg:w-1/5 h-auto object-cover rounded-lg shadow-md"
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={cardTitleInView ? { opacity: 1, scale: 1 } : {}}
                      transition={{ duration: 0.5, delay: 0.2 }}
                    />
                  </div>
                </CardHeader>

                <CardContent className="mt-8">
                  <div className="flex flex-col lg:flex-row gap-8">
                    <div className="w-full lg:w-1/2 relative">
                      <AnimatePresence mode="wait">
                        <motion.img
                          key={currentImageIndex}
                          src={
                            programData[activeProgram].images[currentImageIndex]
                          }
                          alt={`${programData[activeProgram].title} - Image ${
                            currentImageIndex + 1
                          }`}
                          className="w-full h-auto object-cover rounded-lg shadow-md"
                          initial={{ opacity: 0, x: -20 }}
                          animate={{ opacity: 1, x: 0 }}
                          exit={{ opacity: 0, x: 20 }}
                          transition={{ duration: 0.5 }}
                        />
                      </AnimatePresence>
                      <div className="absolute top-1/2 left-0 right-0 flex justify-between transform -translate-y-1/2">
                        <Button
                          variant="outline"
                          size="icon"
                          className="bg-white/80 hover:bg-blue-400"
                          onClick={prevImage}
                          aria-label="Previous image"
                        >
                          <ChevronLeft className="h-4 w-4" />
                        </Button>
                        <Button
                          variant="outline"
                          size="icon"
                          className="bg-white/80 hover:bg-blue-400"
                          onClick={nextImage}
                          aria-label="Next image"
                        >
                          <ChevronRight className="h-4 w-4" />
                        </Button>
                      </div>
                    </div>
                    <ul
                      ref={featuresRef}
                      className="list-disc pl-5 space-y-3 flex-1 text-gray-700"
                    >
                      {programData[activeProgram].features.map(
                        (feature, index) => (
                          <motion.li
                            key={index}
                            initial={{ opacity: 0, x: -20 }}
                            animate={featuresInView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.3, delay: index * 0.15 }}
                            className="text-lg"
                          >
                            {feature}
                          </motion.li>
                        )
                      )}
                    </ul>
                  </div>
                </CardContent>
              </Card>
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </motion.section>
  );
};

// Partners Section

// Summer Camp Section
// const SummerCampSection: React.FC = () => {
//   const summerCampRef = useRef(null);
//   const isSummerCampInView = useInView(summerCampRef, { once: true });
//   const navigate = useNavigate(); // Import useNavigate from react-router-dom

//   const highlights = [
//     {
//       icon: Award,
//       title: "Award-Winning Curriculum",
//       description: "Tailored programs that inspire young minds.",
//     },
//     {
//       icon: Users,
//       title: "Experienced Instructors",
//       description: "Certified experts in robotics and AI.",
//     },
//     {
//       icon: Plane,
//       title: "Hands-On Learning",
//       description: "Engaging activities with real-world applications.",
//     },
//     {
//       icon: CheckCircle,
//       title: "Safe & Fun Environment",
//       description: "Ensuring safety while having the best summer experience.",
//     },
//   ];

//   return (
//     <motion.section
//       ref={summerCampRef}
//       initial="hidden"
//       animate={isSummerCampInView ? "visible" : "hidden"}
//       className="py-20 bg-gradient-to-br from-blue-50 to-white"
//     >
//       <div className="container mx-auto px-6 lg:px-8">
//         {/* Heading and Description */}
//         <motion.div
//           className="text-center mb-12"
//           initial={{ opacity: 0, y: -20 }}
//           animate={isSummerCampInView ? { opacity: 1, y: 0 } : {}}
//           transition={{ duration: 1 }}
//         >
//           <Text size="textxl" className="font-bold text-gray-800 mb-4">
//             Summer Camp 2024
//           </Text>
//           <Text size="textmd" className="text-gray-600">
//             Embark on a summer of discovery and innovation! Our robotics and AI
//             summer camp is designed to spark creativity, foster teamwork, and
//             unlock the potential of tomorrow's innovators.
//           </Text>
//         </motion.div>

//         {/* Highlights */}
//         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
//           {highlights.map((highlight, index) => (
//             <motion.div
//               key={index}
//               className="text-center p-6 bg-white shadow-lg rounded-lg"
//               initial={{ opacity: 0, y: 20 }}
//               animate={isSummerCampInView ? { opacity: 1, y: 0 } : {}}
//               transition={{ duration: 0.8, delay: index * 0.2 }}
//             >
//               <highlight.icon className="w-12 h-12 mx-auto text-blue-500 mb-4" />
//               <Text size="textlg" className="font-semibold text-gray-800 mb-2">
//                 {highlight.title}
//               </Text>
//               <Text size="texts" className="text-gray-600">
//                 {highlight.description}
//               </Text>
//             </motion.div>
//           ))}
//         </div>

//         {/* Call to Action */}
//         <motion.div
//           className="text-center"
//           initial={{ opacity: 0, scale: 0.9 }}
//           animate={isSummerCampInView ? { opacity: 1, scale: 1 } : {}}
//           transition={{ duration: 0.8, delay: 0.6 }}
//         >
//           <div className="flex flex-col items-center space-y-4">
//             <Button
//               size="lg"
//               className="bg-blue-600 text-white hover:bg-blue-700 px-8 py-4 rounded-md"
//               onClick={() => console.log("Register for Summer Camp")}
//             >
//               Register Now
//             </Button>
//             <Button
//               size="lg"
//               variant="outline"
//               className="border-blue-600 text-blue-600 hover:bg-blue-100 px-8 py-4 rounded-md"
//               onClick={() => navigate("/summer-camp")}
//             >
//               Learn More
//             </Button>
//           </div>
//         </motion.div>
//       </div>
//     </motion.section>
//   );
// };

// Main Landing Page Component
const LandingPage: React.FC = () => {
  return (
    <div className="min-h-screen bg-white-a700 divide-y-2">
      <AboutUsSection />

      {/* <StatsSection /> */}
      {/* <SummerCampSection /> */}
      {/* <ProductsSection /> */}

      <ProgramShowcase />
      <PartnersSection />
      <FAQSection />
    </div>
  );
};

export default LandingPage;
