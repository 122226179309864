import React, { useState, useEffect, useRef } from "react";
import { Volume2, VolumeX } from "lucide-react";
import { Button } from "./ui/Button";

const BackgroundMusic: React.FC = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.volume = 0.1; // Set initial volume to 10%
    }

    const handleInteraction = () => {
      if (audio && !hasInteracted) {
        setHasInteracted(true);
        setIsPlaying(true);
        audio.play().catch((error) => {
          console.log("Playback prevented:", error);
          setIsPlaying(false);
        });
      }
      document.removeEventListener("click", handleInteraction);
    };

    document.addEventListener("click", handleInteraction);

    return () => {
      document.removeEventListener("click", handleInteraction);
    };
  }, [hasInteracted]);

  const togglePlayback = () => {
    const audio = audioRef.current;
    if (audio) {
      setIsPlaying((prevIsPlaying) => {
        if (prevIsPlaying) {
          audio.pause();
          return false;
        } else {
          audio.play().catch((error) => {
            console.log("Playback prevented:", error);
            return false;
          });
          return true;
        }
      });
    }
  };

  return (
    <div className="fixed bottom-4 right-4 bg-blue-400 text-white-a700 p-2 rounded-full shadow-lg z-50">
      <audio ref={audioRef} loop src="/music/SummerCamp.mp3">
        Your browser does not support the audio element.
      </audio>
      <Button
        onClick={togglePlayback}
        className="focus:outline-none hover:text-gray-300 transition-colors duration-200"
        aria-label={
          isPlaying ? "Pause background music" : "Play background music"
        }
      >
        {isPlaying ? (
          <Volume2 className="h-6 w-6" />
        ) : (
          <VolumeX className="h-6 w-6" />
        )}
      </Button>
    </div>
  );
};

export default BackgroundMusic;
