import { Card, CardContent } from "../../components/ui/Card";
import { Img } from "../../components/ui/Img";
import { cn } from "../../lib/utils";

interface ScheduleProps {
  className?: string;
}

const CampSchedule = ({ className }: ScheduleProps) => {
  const schedule = [
    {
      day: 1,
      content: "Welcome, Orientation, and POSCO Steel Plant",
      image: "/Images/Camping/Icons/welcome.png",
    },
    {
      day: 2,
      content: "Hyundai ",
      image: "/Images/Camping/Icons/hyundai-icon.png",
    },
    {
      day: 3,
      content: "Cultural Exploration 1",
      image: "/Images/Camping/Icons/south-korea.png",
    },
    {
      day: 4,
      content: "Cultural Exploration 2",
      image: "/Images/Camping/Icons/gyeongbokgung-palace.png",
    },
    {
      day: 5,
      content: "Visit to DCU University",
      image: "/Images/Camping/Icons/DCU.png",
    },
    {
      day: 6,
      content: "MIX CURRICULUM",
      image: "/Images/Camping/Icons/AI.png",
    },
    {
      day: 7,
      content: "MIX CURRICULUM",
      image: "/Images/Camping/Icons/AI.png",
    },
    {
      day: 8,
      content: "MIX CURRICULUM",
      image: "/Images/Camping/Icons/kpop.png",
    },
    {
      day: 9,
      content: "MIX CURRICULUM",
      image: "/Images/Camping/Icons/kpop.png",
    },
    {
      day: 10,
      content: "Final Presentations and Closing Ceremony",
      image: "/Images/Camping/Icons/trophy-ceremony.png",
    },
  ];

  return (
    <Card className={cn("w-full overflow-hidden bg-white-a700", className)}>
      <CardContent className="p-0">
        <div className="bg-gradient-to-br from-yellow-300 to-blue-400 text-white-a700 p-4">
          <h2 className="text-xl font-semibold text-center">Camp Schedule</h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 p-4">
          {schedule.map((day) => (
            <div
              key={day.day}
              className="bg-blue-50 rounded-lg overflow-hidden shadow-md"
            >
              <div className="bg-gradient-to-br from-yellow-300 to-blue-400 text-white-a700 p-2 text-center">
                <h3 className="text-lg font-medium">Day {day.day}</h3>
              </div>
              <div className="p-4 flex flex-col items-center">
                <div className="relative w-16 h-16 mb-2">
                  <Img
                    src={day.image}
                    alt={`Day ${day.day} activity`}
                    className="object-cover rounded-full"
                  />
                </div>
                <p className="text-sm text-center">{day.content}</p>
              </div>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};
export default CampSchedule;
