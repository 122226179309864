import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import {
  ArrowBigLeftDash,
  Star,
  Truck,
  RotateCcw,
  ShieldCheck,
  Phone,
  ChevronLeft,
  ChevronRight,
  X,
} from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  CardFooter,
} from "../../../components/ui/Card";
import { Button } from "../../../components/ui/Button";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../components/ui/Tabs";
import Layout from "../../layout";
import { Img } from "../../../components/ui/Img";

interface Product {
  id: number;
  name: string;
  category: string;
  description: string;
  images: string[];
  features: string[];
  specs: Record<string, string>;
}

const products: Product[] = [
  {
    id: 1,
    name: "Fighting Drone",
    category: "Aerial",
    description:
      "High-speed drone designed for aerial combat simulations and agile maneuvering.",
    images: [
      "/Images/Drones/drone1.png",
      "/Images/Drones/drone1.png",
      "/Images/Drones/drone1.png",
    ],
    features: [
      "High-speed capabilities up to 60 mph",
      "Agile maneuvering with 6-axis gyro stabilization",
      "Durable carbon fiber construction",
      "Advanced flight controls with combat simulation modes",
    ],
    specs: {
      "Max Speed": "60 mph",
      "Flight Time": "25 minutes",
      Camera: "1080p HD with real-time transmission",
      Weight: "1.2 lbs",
      Range: "500 meters",
    },
  },
  {
    id: 2,
    name: "Education Drone",
    category: "Home",
    description:
      "Interactive drone for STEM education, featuring programmable flight paths and sensor integration.",
    images: [
      "/Images/Drones/drone2.png",
      "/Images/DroneEdu/1.png",
      "/Images/DroneEdu/2.png",
      // "/Images/DroneEdu/4.png",
      // "/Images/DroneEdu/4.png",
    ],
    features: [
      "Programmable flight paths",
      "Integrated sensors for data collection",
      "Scratch and Python programming support",
      "Modular design for customization",
    ],
    specs: {
      "Max Speed": "15 mph",
      "Flight Time": "20 minutes",
      Camera: "720p HD",
      Weight: "0.5 lbs",
      Range: "100 meters",
    },
  },
  {
    id: 3,
    name: "Soccer Drone",
    category: "Industrial",
    description:
      "Specially designed drone for aerial soccer games, featuring a protective cage and soft propellers.",
    images: [
      "/Images/Drones/drone3.png",
      "/Images/DroneSoccer/1.png",
      "/Images/DroneSoccer/2.png",
      "/Images/DroneSoccer/3.png",
      "/Images/DroneSoccer/4.png",
    ],
    features: [
      "Protective cage for safe indoor play",
      "Soft propellers to prevent injuries",
      "LED lights for team identification",
      "Responsive controls for precise movements",
    ],
    specs: {
      "Max Speed": "10 mph",
      "Flight Time": "15 minutes",
      Camera: "None",
      Weight: "0.3 lbs",
      Range: "30 meters",
    },
  },
];

const DronesProductPage: React.FC = () => {
  const navigate = useNavigate();
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleProductClick = (product: Product) => {
    setSelectedProduct(product);
    setCurrentImageIndex(0);
  };

  const handlePrevImage = () => {
    if (selectedProduct) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === 0 ? selectedProduct.images.length - 1 : prevIndex - 1
      );
    }
  };

  const handleNextImage = () => {
    if (selectedProduct) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === selectedProduct.images.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  return (
    <Layout>
      <motion.div
        className="container mx-auto px-4 py-8 bg-white-a700 text-gray-800 min-h-screen mt-20"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="flex flex-row justify-between md:flex-row md:justify-start md:gap-4 gap-2 mb-4">
          <Button
            className="md:mr-auto bg-gray-300 hover:bg-gray-400 text-black px-4 py-2 rounded-xl flex items-center justify-center"
            onClick={() => navigate(-1)}
          >
            <ArrowBigLeftDash size={20} className="mr-2" />
            Back
          </Button>
        </div>

        <motion.div
          className="grid grid-cols-1 md:grid-cols-3 gap-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          {products.map((product) => (
            <motion.div
              key={product.id}
              whileHover={{ scale: 1.05 }}
              className="overflow-hidden bg-white-a700 border border-gray-200 rounded-lg shadow cursor-pointer"
              onClick={() => handleProductClick(product)}
            >
              <Card className="h-full flex flex-col">
                <CardHeader className="bg-gradient-to-r from-blue-500 to-purple-500 text-white-a700">
                  <div className="flex justify-between items-center">
                    <CardTitle>{product.name}</CardTitle>
                  </div>
                </CardHeader>
                <CardContent className="flex-grow">
                  <img
                    src={product.images[0]}
                    alt={product.name}
                    className="w-full h-48 object-contain rounded-lg mb-4"
                  />
                  <p className="text-gray-600 line-clamp-2">
                    {product.description}
                  </p>
                </CardContent>
                <CardFooter className="flex justify-between items-center">
                  <Button
                    className="bg-blue-500 hover:bg-blue-600 text-white-a700"
                    onClick={() => handleProductClick(product)}
                  >
                    Details
                  </Button>
                </CardFooter>
              </Card>
            </motion.div>
          ))}
        </motion.div>

        <AnimatePresence>
          {selectedProduct && (
            <motion.div
              className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <motion.div
                className="bg-white-a700 p-8 rounded-lg max-w-3xl w-full max-h-[90vh] overflow-y-auto"
                initial={{ scale: 0.9 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0.9 }}
              >
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-3xl font-bold">{selectedProduct.name}</h2>
                  <Button
                    className="bg-gray-200 hover:bg-gray-300 text-gray-800 rounded-full p-2"
                    onClick={() => setSelectedProduct(null)}
                  >
                    <X size={24} />
                  </Button>
                </div>
                <div className="relative mb-4">
                  <Img
                    src={selectedProduct.images[currentImageIndex]}
                    alt={`${selectedProduct.name} - Image ${
                      currentImageIndex + 1
                    }`}
                    className="w-full h-64 object-contain rounded-lg"
                  />
                  <Button
                    className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-white-a700 bg-opacity-50 hover:bg-opacity-75 text-black rounded-full p-2"
                    onClick={handlePrevImage}
                  >
                    <ChevronLeft size={24} />
                  </Button>
                  <Button
                    className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-white-a700 bg-opacity-50 hover:bg-opacity-75 text-black rounded-full p-2"
                    onClick={handleNextImage}
                  >
                    <ChevronRight size={24} />
                  </Button>
                </div>
                <div className="flex justify-center space-x-2 mb-4">
                  {selectedProduct.images.map((_, index) => (
                    <button
                      key={index}
                      className={`w-3 h-3 rounded-full ${
                        index === currentImageIndex
                          ? "bg-blue-500"
                          : "bg-gray-300"
                      }`}
                      onClick={() => setCurrentImageIndex(index)}
                    />
                  ))}
                </div>

                <p className="text-gray-600 mb-4">
                  {selectedProduct.description}
                </p>
                {/* <div className="bg-green-500 text-white-a700 px-4 py-2 rounded-full shadow-md inline-block mb-4">
                  <span className="font-bold text-lg">
                    ${selectedProduct.price}
                  </span>
                </div> */}

                <Tabs defaultValue="features" className="mt-6">
                  <TabsList className="grid w-full grid-cols-3">
                    <TabsTrigger value="features">Features</TabsTrigger>
                    <TabsTrigger value="specs">Specifications</TabsTrigger>
                    <TabsTrigger value="contact">Contact</TabsTrigger>
                  </TabsList>
                  <TabsContent value="features">
                    <Card>
                      <CardContent className="pt-6">
                        <ul className="list-disc pl-5 space-y-2">
                          {selectedProduct.features.map((feature, index) => (
                            <li key={index}>{feature}</li>
                          ))}
                        </ul>
                      </CardContent>
                    </Card>
                  </TabsContent>
                  <TabsContent value="specs">
                    <Card>
                      <CardContent className="pt-6">
                        <dl className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
                          {Object.entries(selectedProduct.specs).map(
                            ([key, value]) => (
                              <React.Fragment key={key}>
                                <dt className="font-semibold">{key}:</dt>
                                <dd>{value}</dd>
                              </React.Fragment>
                            )
                          )}
                        </dl>
                      </CardContent>
                    </Card>
                  </TabsContent>
                  <TabsContent value="contact">
                    <Card>
                      <CardContent className="pt-6">
                        <Button
                          className="w-full bg-blue-600 hover:bg-blue-700 text-white-a700"
                          onClick={() => navigate("/contact-us")}
                        >
                          <Phone className="mr-2 h-4 w-4" /> Contact for Inquiry
                        </Button>
                      </CardContent>
                    </Card>
                  </TabsContent>
                </Tabs>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </Layout>
  );
};

export default DronesProductPage;
