"use client";

import { motion } from "framer-motion";

import { Mail, Phone, MapPin } from "lucide-react";
import { Input } from "../../components/ui/Input";
import { Textarea } from "../../components/ui/TextArea";
import { Button } from "../../components/ui/Button";
import Layout from "../layout";
import FAQSection from "../FandQSection";

export default function ContactUsPage() {
  const leftColumnVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 20,
        duration: 1.6,
      },
    },
  };

  const rightColumnVariants = {
    hidden: { opacity: 0, x: 100 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 20,
        duration: 1.8,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 1.5 },
    },
  };

  return (
    <Layout>
      <div className="min-h-screen bg-gray-50 px-4 sm:px-6 lg:px-8 py-36">
        <div className="max-w-6xl mx-auto">
          <div className="grid md:grid-cols-2 gap-8 rounded-lg overflow-hidden shadow-lg">
            {/* Left Column */}
            <motion.div
              className="bg- bg-gradient-to-tr  from-blue-500 to-blue-700 p-8 text-white-a700"
              variants={leftColumnVariants}
              initial="hidden"
              animate="visible"
            >
              <motion.h2
                className="text-3xl font-bold mb-4"
                variants={itemVariants}
              >
                Contact us
              </motion.h2>
              <motion.p className="mb-8 text-blue-100" variants={itemVariants}>
                Provide your contact details below, and we'll reach out to help
                you explore our offerings. Don't miss the chance to connect with
                us and discover how we can innovate together.
              </motion.p>

              <div className="space-y-6">
                <motion.div variants={itemVariants}>
                  <h3 className="font-semibold mb-2">Message Us</h3>
                  <div className="flex items-center space-x-3 text-blue-100">
                    <Mail className="h-5 w-5" />
                    <span>admin@scienceboxvietnam.vn</span>
                  </div>
                </motion.div>

                <motion.div variants={itemVariants}>
                  <h3 className="font-semibold mb-2">Call us</h3>
                  <div className="flex items-center space-x-3 text-blue-100">
                    <Phone className="h-5 w-5" />
                    <span>+84817665064</span>
                  </div>
                </motion.div>

                <motion.div variants={itemVariants}>
                  <h3 className="font-semibold mb-2">Address</h3>
                  <div className="flex items-center space-x-3 text-blue-100">
                    <MapPin className="h-5 w-5" />
                    <span>475A Điện Biên Phủ, P.25, Q.Bình Thạnh, TPHCM</span>
                  </div>
                </motion.div>
              </div>
            </motion.div>

            {/* Right Column */}
            <motion.div
              className="bg-white p-8"
              variants={rightColumnVariants}
              initial="hidden"
              animate="visible"
            >
              <form className="space-y-6">
                <div className="grid grid-cols-2 gap-4 ">
                  <motion.div variants={itemVariants}>
                    <Input
                      placeholder="Enter first name"
                      className="w-full px-4 py-2 border rounded-md border-solid"
                    />
                  </motion.div>
                  <motion.div variants={itemVariants}>
                    <Input
                      placeholder="Enter last name"
                      className="w-full px-4 py-2 border rounded-md border-solid"
                    />
                  </motion.div>
                </div>

                <div className="grid grid-cols-2 gap-4 ">
                  <motion.div variants={itemVariants}>
                    <Input
                      type="email"
                      placeholder="Enter email address"
                      className="w-full px-4 py-2 border rounded-md border-solid"
                    />
                  </motion.div>
                  <motion.div variants={itemVariants}>
                    <Input
                      type="tel"
                      placeholder="Enter phone number"
                      className="w-full px-4 py-2 border rounded-md border-solid"
                    />
                  </motion.div>
                </div>

                <motion.div variants={itemVariants}>
                  <Textarea
                    placeholder="Type your message here..."
                    className="w-full px-4 py-2 border rounded-md h-32 border-solid"
                  />
                </motion.div>

                <motion.div
                  variants={itemVariants}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <Button
                    type="submit"
                    className="w-full bg-blue-500 hover:bg-blue-600 text-white-a700 py-2 px-4 rounded-md transition-colors"
                  >
                    Submit
                  </Button>
                </motion.div>
              </form>
            </motion.div>
          </div>
        </div>
        <FAQSection />
      </div>
    </Layout>
  );
}
