import { motion, useInView, AnimatePresence } from "framer-motion";
import { useEffect, useRef, useState } from "react";

// Define the list of all partners
const allPartners = [
  {
    id: 1,
    title: "Best Choice",
    image: "/Images/logo/asomeit.png",
  },
  {
    id: 2,
    title: "Premium Quality",
    image: "/Images/logo/bis.png",
  },
  {
    id: 3,
    title: "1st Choice",
    image: "/Images/logo/bk.png",
  },
  {
    id: 4,
    title: "Highest Quality",
    image: "/Images/logo/CIS.png",
  },
  {
    id: 5,
    title: "Limited Edition",
    image: "/Images/logo/dcu.png",
  },
  {
    id: 6,
    title: "1st Choice Premium",
    image: "/Images/logo/Drone division.png",
  },
  {
    id: 7,
    title: "Limited Edition Premium",
    image: "/Images/logo/GBICT.png",
  },
  { id: 8, title: "Top Tier", image: "/Images/logo/HUTECH.png" },
  {
    id: 9,
    title: "Elite Choice",
    image: "/Images/logo/IS.png",
  },
  {
    id: 10,
    title: "Premium Selection",
    image: "/Images/logo/jeonnam.png",
  },
  {
    id: 11,
    title: "1st Choice",
    image: "/Images/logo/royal.png",
  },
  {
    id: 12,
    title: "Highest Quality",
    image: "/Images/logo/SAMSUNG.png",
  },
  {
    id: 13,
    title: "Limited Edition",
    image: "/Images/logo/sb.png",
  },
  {
    id: 14,
    title: "1st Choice Premium",
    image: "/Images/logo/SIKS.png",
  },
  {
    id: 15,
    title: "Limited Edition Premium",
    image: "/Images/logo/SSIS.png",
  },
  { id: 16, title: "Top Tier", image: "/Images/logo/tdt.png" },
  {
    id: 17,
    title: "Elite Choice",
    image: "/Images/logo/UEF.png",
  },
  {
    id: 18,
    title: "Premium Selection",
    image: "/Images/logo/vas.png",
  },
];
const PartnersSection: React.FC = () => {
  const [visiblePartners, setVisiblePartners] = useState(
    allPartners.slice(0, 8)
  );
  const [isMobile, setIsMobile] = useState(false);

  // Check for screen size
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!isMobile) {
      const interval = setInterval(() => {
        setVisiblePartners((prevPartners) => {
          const newPartners = [...prevPartners];
          const removedPartner = newPartners.shift();
          const nextPartner = allPartners.find(
            (p) => !prevPartners.includes(p)
          );
          if (nextPartner) {
            newPartners.push(nextPartner);
          } else if (removedPartner) {
            newPartners.push(removedPartner);
          }
          return newPartners;
        });
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [isMobile]);

  const containerRef = useRef(null);
  const containerInView = useInView(containerRef, { once: true });

  return (
    <section className="py-16 bg-[#FAF9F9]" ref={containerRef}>
      <div className="container mx-auto px-4">
        <motion.div
          className="text-center max-w-3xl mx-auto mb-12"
          initial={{ opacity: 0, y: -20 }}
          animate={containerInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 2 }}
        >
          <motion.h2
            className="text-[2.5rem] font-bold text-[#1A1A1A] mb-4"
            initial={{ opacity: 0, y: -20 }}
            animate={containerInView ? { opacity: 1, y: 0 } : {}}
          >
            Our Trustful Partners
          </motion.h2>
          <motion.p
            className="text-gray-600"
            initial={{ opacity: 0 }}
            animate={containerInView ? { opacity: 1 } : {}}
            transition={{ duration: 2, delay: 1.2 }}
          >
            Collaborating for mutual growth and success with industry leaders.
          </motion.p>
        </motion.div>

        {isMobile ? (
          <div className="grid grid-cols-2 gap-4 justify-items-center">
            {allPartners.map((partner, index) => (
              <motion.div
                key={partner.id}
                className="w-32 h-32 flex items-center justify-center transition-transform duration-300 hover:scale-105"
                initial={{ opacity: 0, y: 20 }}
                animate={containerInView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                whileHover={{
                  boxShadow: "0 0 10px 4px rgba(0, 123, 255, 0.5)",
                }}
              >
                <img
                  src={partner.image}
                  alt={partner.title}
                  className="w-full h-full object-contain filter  transition-all duration-300"
                />
              </motion.div>
            ))}
          </div>
        ) : (
          <motion.div
            ref={containerRef}
            className="grid grid-cols-4 gap-8 items-center justify-items-center"
            initial="hidden"
            animate={containerInView ? "visible" : "hidden"}
            variants={{
              hidden: { opacity: 0 },
              visible: { opacity: 1, transition: { staggerChildren: 0.1 } },
            }}
          >
            <AnimatePresence mode="popLayout">
              {visiblePartners.map((partner, index) => (
                <motion.div
                  key={partner.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  className="w-32 h-32 flex items-center justify-center"
                >
                  <img
                    src={partner.image}
                    alt={partner.title}
                    className="w-full h-full object-contain filter  transition-all duration-300"
                  />
                </motion.div>
              ))}
            </AnimatePresence>
          </motion.div>
        )}
      </div>
    </section>
  );
};

export default PartnersSection;
