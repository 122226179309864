import { motion, useInView } from "framer-motion";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../components/ui/Accordion";
import { useRef } from "react";

interface FAQ {
  question: string;
  answer: string | string[];
}

const faqData: FAQ[] = [
  {
    question: "What programs does MetaSquare offer?",
    answer:
      "We offer training programs in robotics, artificial intelligence (AI), programming, and soft skills. Courses are tailored to students' ages and skill levels, ranging from elementary to high school.",
  },
  {
    question: "What age groups are MetaSquare's programs designed for?",
    answer:
      "Our programs cater to students aged 6 to 18, with content customized for each age group.",
  },
  {
    question: "Does MetaSquare provide online courses?",
    answer:
      "Currently, MetaSquare offers in-person classes at our center. However, we are developing an online learning platform to expand accessibility.",
  },
  {
    question: "Why should I choose MetaSquare?",
    answer: [
      "High-quality instructors with professional expertise.",
      "Internationally accredited programs.",
      "A creative and engaging learning environment.",
    ],
  },
];

const FAQSection: React.FC = () => {
  const faqRef = useRef(null);
  const faqInView = useInView(faqRef, { once: true });

  return (
    <motion.section
      className="py-20"
      ref={faqRef}
      initial={{ opacity: 0 }}
      animate={faqInView ? { opacity: 1 } : {}}
      transition={{ duration: 2 }}
    >
      <div className="container mx-auto px-4">
        <motion.h2
          className="text-center font-bold mb-4 text-gray-900 text-4xl"
          initial={{ opacity: 0, y: -20 }}
          animate={faqInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1 }}
        >
          Frequently Asked Questions
        </motion.h2>
        <motion.p
          className="text-center text-gray-500 mb-12"
          initial={{ opacity: 0 }}
          animate={faqInView ? { opacity: 1 } : {}}
          transition={{ duration: 1.6, delay: 1.2 }}
        ></motion.p>
        <div className="max-w-3xl mx-auto">
          <Accordion type="single" collapsible>
            {faqData.map((faq: FAQ, index) => (
              <AccordionItem key={index} value={`item-${index}`}>
                <motion.div
                  className="bg-white-a700 rounded-lg shadow-sm mb-3"
                  initial={{ opacity: 0, y: 20 }}
                  animate={faqInView ? { opacity: 1, y: 0 } : {}}
                  transition={{ duration: 1.5, delay: index * 0.1 }}
                >
                  <AccordionTrigger className="font-medium text-gray-800 px-4 py-3 w-full text-left">
                    {faq.question}
                  </AccordionTrigger>
                  <AccordionContent className="px-4 py-2 text-gray-600">
                    <ul>
                      {Array.isArray(faq.answer) ? (
                        faq.answer.map((item: string, idx: number) => (
                          <li key={idx}>{item}</li>
                        ))
                      ) : (
                        <li>{faq.answer}</li> // Handle single string case
                      )}
                    </ul>
                  </AccordionContent>
                </motion.div>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </motion.section>
  );
};

export default FAQSection;
