"use client";

import React, { useState, useMemo, useEffect } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/ui/Button";
import {
  Clock,
  User,
  CircuitBoardIcon as Circuit,
  BotIcon as Robot,
  Cpu,
  DrillIcon as Drone,
  CuboidIcon as Cube,
  ChevronDown,
} from "lucide-react";

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  CardFooter,
} from "../../components/ui/Card";
import { Tabs, TabsList, TabsTrigger } from "../../components/ui/Tabs";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/Accordion";
import Layout from "../layout";

const courses = [
  {
    id: 1,
    title: "Electronic Basics",
    image: "/Images/Courses/course4.png",
    description:
      "Comprehensive course focusing on electronic components, basic circuits, and building simple electronic systems. Learn fundamental electrical concepts and circuit design.",
    duration: "8 weeks",
    category: "robotics",
    icon: Circuit,
    featured: true,
    classSize: "20 students",
  },
  {
    id: 2,
    title: "Robotics with AI & IoT",
    image: "/Images/Courses/course1.jpg",
    description:
      "Master programming and control systems using Arduino, combined with Robotics, IoT, and AI applications. Hands-on experience with modern robotics technology.",
    duration: "30 weeks",
    category: "robotics",
    icon: Robot,
    featured: true,
    classSize: "15 students",
  },
  {
    id: 3,
    title: "Algorithm Robotics",
    image: "/Images/Courses/course3.jpg",
    description:
      "Deep dive into robot programming and control through advanced algorithms. Learn to implement fundamental algorithms for robot navigation and control.",
    duration: "24 weeks",
    category: "robotics",
    icon: Cpu,
    featured: true,
    classSize: "18 students",
  },
  {
    id: 4,
    title: "Drone AI Technology",
    image: "/Images/Courses/course5.jpg",
    description:
      "Explore autonomous logic, technology integration, and drone construction principles. Learn about flight mechanics and specialized drone programming.",
    duration: "20 weeks",
    category: "drones",
    icon: Drone,
    featured: true,
    classSize: "12 students",
  },
  {
    id: 5,
    title: "3D Design for Robotics",
    image: "/Images/Courses/course6.jpg",
    description:
      "Master 3D design tools and techniques for creating robotic components and models. Learn to design and prototype using industry-standard 3D modeling software.",
    duration: "8 weeks",
    category: "both",
    icon: Cube,
    featured: true,
    classSize: "16 students",
  },
];
const testimonials = [
  {
    id: 1,
    name: "John Smith",
    course: "Drone AI Technology",
    quote:
      "This course opened my eyes to the incredible potential of drone technology. Highly recommended!",
  },
  {
    id: 2,
    name: "Emily Davis",
    course: "Algorithm Robotics",
    quote:
      "The hands-on projects in this course really helped me understand complex robotics concepts. Great experience!",
  },
  {
    id: 3,
    name: "Michael Johnson",
    course: "Robotics with AI & IoT",
    quote:
      "Combining IoT and AI in this course was fascinating. It's given me new ideas for my research projects.",
  },
];

const faqs = [
  {
    question: "What prerequisites are required for these courses?",
    answer:
      "Prerequisites vary by course. For Electronic Basics, no prior experience is needed. Other courses may require basic programming knowledge or completion of prerequisite courses.",
  },
  {
    question: "Are these courses suitable for beginners?",
    answer:
      "Yes, our curriculum is designed with a progressive learning path. We recommend starting with Electronic Basics if you're new to robotics and electronics.",
  },
  {
    question: "How much time should I dedicate to these courses each week?",
    answer:
      "Most courses require 8-10 hours per week, including lectures, practical sessions, and projects. The Electronic Basics course might require less time.",
  },
  {
    question: "Will I receive a certificate upon completion?",
    answer:
      "Yes, upon successful completion of each course, you will receive a digital certificate that you can share on your resume or LinkedIn profile.",
  },
];

export default function EnhancedCourseScreen() {
  const [selectedCategory, setSelectedCategory] = useState("all");
  const navigate = useNavigate();

  const filteredCourses = useMemo(() => {
    if (selectedCategory === "all") return courses;
    return courses.filter(
      (course) =>
        course.category === selectedCategory || course.category === "both"
    );
  }, [selectedCategory, courses]);

  useEffect(() => {
    console.log("Selected category:", selectedCategory);
  }, [selectedCategory]);

  const sectionVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  const handleContactClick = () => {
    navigate("/contact-us");
  };

  return (
    <Layout>
      <div className="bg-gray-100 min-h-screen pt-20">
        {/* Hero Section */}
        <motion.section
          className="bg-gradient-to-r from-blue-600 to-yellow-400 text-white-a700 py-20"
          initial="hidden"
          whileInView="visible"
          variants={sectionVariants}
        >
          <div className="container mx-auto px-4 text-center">
            <h1 className="text-4xl md:text-5xl font-bold mb-4">
              Master Robotics and Drone Technology
            </h1>
            <p className="text-xl mb-8">
              From basic electronics to advanced AI-powered systems
            </p>
            <div className="relative z-10 flex flex-col items-center justify-end h-full pb-16">
              <Button
                className={`
                  px-8 py-4 bg-white bg-opacity-20 
                  text-white-a700 rounded-lg hover:bg-opacity-20 
                  transition duration-300 grid items-center justify-center 
                  text-lg font-semibold text-center
                  ${"translate-y-2"}
                `}
                aria-label="Scroll for more content"
              >
                Scroll for more
                <div
                  className={`flex text-center justify-center ${"opacity-75 animate-bounce"}`}
                >
                  <ChevronDown className="text-white w-8 h-8" />
                </div>
              </Button>
              <div className="absolute inset-0">
                {[...Array(20)].map((_, i) => (
                  <motion.div
                    key={i}
                    initial={{ opacity: 0, scale: 0 }}
                    animate={{ opacity: 0.1, scale: 1 }}
                    transition={{
                      duration: 2,
                      delay: i * 0.1,
                      repeat: Infinity,
                      repeatType: "reverse",
                    }}
                    className="absolute rounded-full bg-white-a700"
                    style={{
                      left: `${Math.random() * 100}%`,
                      top: `${Math.random() * 100}%`,
                      width: `${Math.random() * 50 + 10}px`,
                      height: `${Math.random() * 50 + 10}px`,
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        </motion.section>

        {/* Filter Section */}
        <motion.section
          className="bg-white-a700 py-16"
          initial="hidden"
          whileInView="visible"
          variants={sectionVariants}
        >
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-8 text-center">Our Courses</h2>
            <Tabs
              value={selectedCategory}
              onValueChange={setSelectedCategory}
              className="mb-8"
            >
              <TabsList className="grid w-full grid-cols-3">
                <TabsTrigger value="all">All Courses</TabsTrigger>
                <TabsTrigger value="drones">Drones</TabsTrigger>
                <TabsTrigger value="robotics">Robotics</TabsTrigger>
              </TabsList>
            </Tabs>
            <motion.div
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
              initial="hidden"
              animate="visible"
              variants={sectionVariants}
            >
              {filteredCourses.map((course) => (
                <Card key={course.id} className="h-full flex flex-col">
                  <CardHeader>
                    <div className="relative">
                      <img
                        src={course.image}
                        alt={course.title}
                        className="w-full h-48 object-cover rounded-t-lg"
                      />
                      <course.icon className="absolute bottom-4 right-4 w-8 h-8 text-blue-600 bg-white-a700 rounded-full p-1" />
                    </div>
                  </CardHeader>
                  <CardContent className="flex-grow">
                    <CardTitle className="mb-2">{course.title}</CardTitle>
                    <CardDescription className="mb-4">
                      {course.description}
                    </CardDescription>
                    <div className="flex items-center text-sm text-gray-500 mb-2">
                      <User className="mr-2 h-4 w-4" />
                      {course.classSize}
                    </div>
                    <div className="flex items-center text-sm text-gray-500">
                      <Clock className="mr-2 h-4 w-4" />
                      {course.duration}
                    </div>
                  </CardContent>
                  <CardFooter>
                    <Button
                      className="w-full bg-blue-600 text-white-a700 hover:bg-blue-700"
                      onClick={handleContactClick}
                    >
                      Contact Us
                    </Button>
                  </CardFooter>
                </Card>
              ))}
            </motion.div>
          </div>
        </motion.section>

        {/* Testimonials */}
        <motion.section
          className="bg-gray-200 py-16"
          initial="hidden"
          whileInView="visible"
          variants={sectionVariants}
        >
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-8 text-center">
              What Our Students Say
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {testimonials.map((testimonial) => (
                <Card key={testimonial.id}>
                  <CardContent className="pt-6">
                    <p className="italic mb-4">"{testimonial.quote}"</p>
                    {/* <p className="font-semibold">{testimonial.name}</p> */}
                    <p className="text-sm text-gray-500">
                      {testimonial.course}
                    </p>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </motion.section>

        {/* FAQ */}
        <motion.section
          className="py-16"
          initial="hidden"
          whileInView="visible"
          variants={sectionVariants}
        >
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-8 text-center">
              Frequently Asked Questions
            </h2>
            <Accordion
              type="single"
              collapsible
              className="w-full max-w-3xl mx-auto"
            >
              {faqs.map((faq, index) => (
                <AccordionItem key={index} value={`item-${index}`}>
                  <AccordionTrigger>{faq.question}</AccordionTrigger>
                  <AccordionContent>{faq.answer}</AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </motion.section>
      </div>
    </Layout>
  );
}
