"use client";

import { useState } from "react";
import { Card, CardContent } from "../../components/ui/Card";
import { Separator } from "../../components/ui/Separator";
import { Button } from "../../components/ui/Button";
import { ChevronLeft, ChevronRight, Menu } from "lucide-react";
import { Img } from "../../components/ui/Img";

interface DayDetail {
  day: number;
  title: string;
  images: Array<{
    src: string;
    alt: string;
  }>;
  culturalImmersion?: string[];
  kTraditionalDance?: string[];
  kpopDance?: string[];
  creativeSports?: string[];
}

const campDetails: DayDetail[] = [
  {
    day: 1,
    title: "Welcome, Orientation, and Korean Culture Overview",
    images: [
      {
        src: "/Images/Camping/summer-camp/POSCO.jpg",
        alt: "POSCO.jpg",
      },
      {
        src: "Images/Camping/summer-camp/POSCO2.jpg",
        alt: "POSCO2.jpg",
      },
    ],
    culturalImmersion: [
      "Introduction to the history and production process of steel.",
      "Visit the main production areas and modern technology.",
      "Tour the product showroom",
    ],
  },
  {
    day: 2,
    title: "Hyundai ",
    images: [
      {
        src: "/Images/Camping/summer-camp/Huyndai.jpg",
        alt: "Korean Calligraphy Workshop",
      },
      {
        src: "/Images/Camping/summer-camp/Huyndai2.jpg",
        alt: "Traditional Korean Games",
      },
    ],
    culturalImmersion: [
      "Travel to the Hyundai headquarters or factory to learn about its history and production process. ",
      "Visit the production lines, product display areas, and interact with the staff",
    ],
  },
  {
    day: 3,
    title:
      "Cultural Exploration 1: E-World Theme Park: Exploration and hands-on experiences, Gyeongju World Theme Park, Gyeongju World California Beach  ",

    images: [
      {
        src: "/Images/Camping/summer-camp/park.jpg",
        alt: "Cooking Class",
      },
      {
        src: "/Images/Camping/summer-camp/park2.jpg",
        alt: "K-POP Training",
      },
    ],
    culturalImmersion: [
      "Travel to the park to explore its key attractions and enjoy light activities.",
      "Rest and have lunch at the park's restaurant",
      "Experience exciting games, watch performances, or participate in special events (if available)",
    ],
  },
  {
    day: 4,
    title:
      "Cultural Exploration 2: Samsunghyeon History and Culture Museum & Park, Jain Hyanggyo, Gyeongju Expo Park, Daereungwon Tomb Complex in Gyeongju",
    images: [
      {
        src: "/Images/Camping/summer-camp/history.jpg",
        alt: "Hanok Village",
      },
      {
        src: "/Images/Camping/summer-camp/history2.jpg",
        alt: "Industry Tour",
      },
    ],
    culturalImmersion: [
      "Visit the museum to learn about artifacts and special exhibitions.",
      "Stroll around the exhibition park",
      "explore outdoor display areas, and participate in interactive activities (if available).",
    ],
  },
  {
    day: 5,
    title: "Visit to DCU University",
    images: [
      {
        src: "/Images/Camping/summer-camp/daegu.png",
        alt: "Engineering Workshop",
      },
      {
        src: "/Images/Camping/summer-camp/daegu2.png",
        alt: "Traditional Performance",
      },
    ],
    culturalImmersion: [
      "Travel to DCU University, tour the campus, including the academic buildings and facilities.",
      "Meet with faculty or students, and attend sessions about the academic programs and student life.",
    ],
  },
  {
    day: 6,
    title: "MIX CURRICULUM",
    images: [
      {
        src: "/Images/Camping/summer-camp/plant-kit.jpg",
        alt: "Robot Assembly",
      },
      {
        src: "/Images/Camping/section3/section3-1.jpg",
        alt: "K-POP Training",
      },
      {
        src: "/Images/Camping/summer-camp/archery.jpg",
        alt: "K-POP Training",
      },
    ],
    culturalImmersion: [
      "Explore and experience the Invention Experience Center",
      "Artificial Intelligence] ) Physical Computer Plant Pot Creation",
    ],

    kTraditionalDance: [
      "Learn the fundamentals of traditional Korean dance rhythms ",
      "Training and experience with the Modeumbuk",
    ],
    kpopDance: [
      "Learn applied K-POP dance moves and rhythms",
      "Learn K-POP remix performance (1st section)",
    ],
    creativeSports: [
      "Traditional Korean folk game : Tuho ",
      "(arrow throwing game)- Creative Sport: archery",
    ],
  },
  {
    day: 7,
    title: "MIX CURRICULUM",
    images: [
      {
        src: "/Images/Camping/summer-camp/museum.jpg",
        alt: "AI Project",
      },
      {
        src: "/Images/Camping/section3/section3-3.jpg",
        alt: "Traditional Dance",
      },
      {
        src: "/Images/Camping/summer-camp/ganggangsullae.jpg",
        alt: "Traditional Dance",
      },
    ],
    culturalImmersion: [
      "Explore and experience the RoboLife Museum",
      "[Artificial Intelligence] Humanoid robot coding",
    ],
    kTraditionalDance: [
      "Learn basic movements of Ganggangsullae (traditional circle dance)",
      "Complete a group performance of ganggangsullae",
    ],
    kpopDance: [
      "Learn advanced K-POP dance moves and rhythms",
      "Learn K-POP remix performance (2nd section)",
    ],
    creativeSports: [
      "Traditional Korean folk game : Jaegi Chagi: kicking shuttlecock game)",
      " Creative Sport: Bigminton (badminton with oversized rackets)",
    ],
  },
  {
    day: 8,
    title: "MIX CURRICULUM",
    images: [
      {
        src: "/Images/Camping/summer-camp/children.jpg",
        alt: "K-pop Dance Workshop",
      },
      {
        src: "/Images/Camping/section3/section3-2.jpg",
        alt: "K-pop Dance Workshop",
      },
      {
        src: "/Images/Camping/summer-camp/yunori.jpg",
        alt: "Korean Games",
      },
    ],

    culturalImmersion: [
      "Explore and experience the Pohang Accelerator Research Center",
      "Convergence of Scientific Technologies",
    ],
    kTraditionalDance: [
      "Learn basic techniques and movements for Sogo (small traditional drum)",
      "Complete a group performance with Sogo",
    ],
    kpopDance: [
      "Learn applied K-POP dance moves and rhythms",
      "Learn K-POP remix performance (3rd section)",
    ],
    creativeSports: [
      "Traditional Korean folk game: Yutnori (board game with wooden sticks)",
      "Creative Sport: Flying Disk Yutnori (fusion of frisbee and Yutnori)",
    ],
  },
  {
    day: 9,
    title: "K-pop Music Video Project",
    images: [
      {
        src: "/Images/Camping/summer-camp/apple.jpg",
        alt: "Music Video Filming",
      },
      {
        src: "/Images/Camping/summer-camp/MaskDance.jpg",
        alt: "Music Video Editing",
      },
      {
        src: "/Images/Camping/summer-camp/tugofwar.jpg",
        alt: "Music Video Editing",
      },
    ],
    culturalImmersion: [
      "Explore and experience the Apple Developer Academy",
      "Plan concept apps by experiencing Apple's iOS operating system",
    ],
    kTraditionalDance: [
      "Learn basic movements of Talchum (mask dance)",
      "Perform a group Talchum with Hansam (traditional silk sleeves)",
    ],
    kpopDance: [
      "Create individual characteristics for K-POP performances",
      "Learn K-POP remix performance (4th section)",
    ],
    creativeSports: [
      "Traditional Korean folk game: Tug of War",
      "Creative Sport: Dice Shuttle Run (a mix of dice rolling and shuttle run)",
    ],
  },
  {
    day: 10,
    title: "AI & Sports Day & K-Dance Competition",
    images: [
      {
        src: "/Images/Camping/section4/section4-2.jpg",
        alt: "Final Presentations",
      },
      {
        src: "/Images/Camping/section4/section4-3.jpg",
        alt: "Closing Ceremony",
      },
    ],
    culturalImmersion: [
      "Teams present projects on AI, K-pop, and language",
      "Showcase the completed K-pop music video",
      "Awards and certificate ceremony to conclude the camp",
    ],
    kTraditionalDance: ["K-Traditional Dance competition"],
    kpopDance: ["K-POP Dance competition"],
    creativeSports: ["Mini sports competition (Team Blue vs. Team White)"],
  },
];

const CampDetails = () => {
  const [currentDay, setCurrentDay] = useState(1);

  const currentDayData =
    campDetails.find((day) => day.day === currentDay) || campDetails[0];

  const navigateDay = (direction: "prev" | "next") => {
    if (direction === "prev" && currentDay > 1) {
      setCurrentDay(currentDay - 1);
    } else if (direction === "next" && currentDay < campDetails.length) {
      setCurrentDay(currentDay + 1);
    }
  };

  return (
    <div className="relative">
      <Card className="bg-white">
        <CardContent className="p-6">
          <div className="space-y-6">
            <div className="flex justify-between items-start">
              <div>
                <h2 className="text-2xl font-bold">Day {currentDayData.day}</h2>
                <h3 className="text-xl text-gray-600">
                  {currentDayData.title}
                </h3>
              </div>
              <div className="text-right text-gray-500">
                <p>Summer Camp 2025</p>
                <p>Location: Seoul, Korea</p>
              </div>
            </div>

            {/* Navigation buttons moved here, right after the header */}

            <div className="text-sm text-gray-500 p-2">
              Day {currentDay} of {campDetails.length}
              <div
                className={`grid ${
                  currentDayData.images.length >= 3
                    ? "grid-cols-3"
                    : "grid-cols-1 md:grid-cols-2"
                } gap-4`}
              >
                {currentDayData.images.map((image, index) => (
                  <Img
                    key={index}
                    src={image.src}
                    alt={image.alt}
                    className="w-full h-full object-cover"
                  />
                ))}
              </div>
            </div>
            <div className="flex justify-between items-center py-2">
              <Button
                variant="outline"
                onClick={() => navigateDay("prev")}
                disabled={currentDay === 1}
                className="w-[120px]"
              >
                <ChevronLeft className="h-4 w-4 mr-2" />
                {/* Day {currentDay - 1} */}
              </Button>

              <Button
                variant="outline"
                onClick={() => navigateDay("next")}
                disabled={currentDay === campDetails.length}
                className="w-[120px]"
              >
                {/* Day {currentDay + 1} */}
                <ChevronRight className="h-4 w-4 ml-2" />
              </Button>
            </div>

            <Separator />
            <div>
              <h4 className="font-bold mb-3">Daily Activities</h4>
              <div className="grid md:grid-cols-2 gap-6">
                {currentDayData.culturalImmersion &&
                  currentDayData.culturalImmersion.length > 0 && (
                    <div>
                      <h5 className="font-semibold text-gray-600 mb-2">
                        Cultural Immersion / STEAM & Technology
                      </h5>
                      <ul className="list-disc pl-5 space-y-1">
                        {currentDayData.culturalImmersion.map((item, index) => (
                          <li key={index} className="text-gray-600">
                            {item}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                {(currentDayData.kTraditionalDance &&
                  currentDayData.kTraditionalDance.length > 0) ||
                (currentDayData.kpopDance &&
                  currentDayData.kpopDance.length > 0) ? (
                  <div>
                    <h5 className="font-semibold text-gray-600 mb-2">
                      K-POP and Performance
                    </h5>
                    <ul className="list-disc pl-5 space-y-1">
                      {currentDayData.kTraditionalDance?.map((item, index) => (
                        <li key={index} className="text-gray-600">
                          {item}
                        </li>
                      ))}
                      {currentDayData.kpopDance?.map((item, index) => (
                        <li key={index} className="text-gray-600">
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}
              </div>
            </div>

            <Separator />

            {currentDayData.creativeSports &&
              currentDayData.creativeSports.length > 0 && (
                <div className="grid md:grid-cols-2 gap-6">
                  <div>
                    <h4 className="font-bold mb-3">Creative Sports</h4>
                    <ul className="list-disc pl-5 space-y-1">
                      {currentDayData.creativeSports.map((item, index) => (
                        <li key={index} className="text-gray-600">
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default CampDetails;
