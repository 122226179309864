import { ChevronDown } from "lucide-react";
import React, { useState } from "react";

export default function ImageSection() {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const scrollToContent = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    });
  };
  return (
    <div
      className="relative w-full h-screen overflow-hidden z-0" // Fullscreen and lower z-index
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* Background Video */}
      <video
        src="/video/video.mp4" // Update with your actual video path
        autoPlay
        loop
        muted
        playsInline
        className="absolute inset-0 w-full h-full object-cover"
      ></video>

      {/* Overlay effect on hover */}
      {isHovered && (
        <div className="absolute inset-0  opacity-75 transition-opacity"></div>
      )}

      <div className="relative z-10 flex flex-col items-center justify-end h-full pb-16">
        <button
          onClick={scrollToContent}
          className={`
            px-8 py-4 bg-white bg-opacity-20 
            text-white-a700 rounded-lg hover:bg-opacity-20 
            transition duration-300 grid items-center justify-center 
            text-lg font-semibold  text-center
            ${isHovered ? "translate-y-0" : "translate-y-2"}
          `}
          aria-label="Scroll for more content"
        >
          Scroll for more
          <div
            className={`
          flex text-center justify-center
        ${"opacity-75 animate-bounce"}
      `}
          >
            <ChevronDown className="text-white w-8 h-8" />
          </div>
        </button>
      </div>

      {/* Bouncing arrow (optional, for additional visual cue) */}
    </div>
  );
}
