import ImageSection from "./ImageSection";
import LandingPage from "./landingPage";
import Layout from "../layout";
// import VideoSection from "./videobackground";

export default function HomePage() {
  return (
    <Layout>
      <div className="relative flex w-full flex-col bg-gray-200">
        <div className="relative z-30 flex flex-col  ">
          <ImageSection />
          <LandingPage />
        </div>
      </div>
    </Layout>
  );
}
