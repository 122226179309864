import React from "react";
import { motion } from "framer-motion";
import { Button } from "../../components/ui/Button";
import { Img } from "../../components/ui/Img";
import Layout from "../layout";
import FAQSection from "../FandQSection";
import PartnersSection from "../PartnerSection";
import {
  Atom,
  Eye,
  Target,
  Cpu,
  Users,
  Lightbulb,
  TrendingUp,
} from "lucide-react";
import { useNavigate } from "react-router-dom";

export default function AboutUsPage() {
  const navigate = useNavigate(); // Initialize navigate function
  const stats = [
    {
      value: "20K+",
      label: "Students Trained",
      icon: <Users className="w-6 h-6 text-blue-500" />,
    },
    {
      value: "50+",
      label: "Tech Courses",
      icon: <Cpu className="w-6 h-6 text-green-500" />,
    },
    {
      value: "98%",
      label: "Job Placement Rate",
      icon: <TrendingUp className="w-6 h-6 text-purple-500" />,
    },
    {
      value: "100%",
      label: "Industry-Aligned Curriculum",
      icon: <Lightbulb className="w-6 h-6 text-yellow-500" />,
    },
  ];

  return (
    <Layout>
      <div className="bg-gray-50">
        {/* Hero Section */}
        <motion.section
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="container mx-auto px-4 py-24"
        >
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <motion.div
              initial={{ x: -100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="relative  rounded-lg overflow-hidden shadow-2xl"
            >
              <Img
                src="/Images/LandingPage/AboutUs.jpg"
                alt="Innovative learning environment"
                className="object-fill"
              />
            </motion.div>
            <motion.div
              initial={{ x: 100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.4 }}
              className="bg-white-a700 p-8 rounded-lg shadow-lg"
            >
              <h1 className="text-4xl font-bold text-gray-800 mb-6">
                Shaping the Future of Tech Education
              </h1>
              <p className="text-gray-600 text-lg leading-relaxed mb-6">
                At MetaSquare, we're at the forefront of technology and
                creativity education in Vietnam. Our cutting-edge programs blend
                innovative learning methodologies with industry-leading
                technologies to prepare the next generation of digital pioneers.
              </p>
              <Button
                variant="default"
                size="lg"
                className="bg-blue-600 hover:bg-blue-700 text-white-a700"
              >
                Explore Our Programs
              </Button>
            </motion.div>
          </div>
        </motion.section>

        {/* Stats Section */}
        {/* <motion.section
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="bg-white-a700 py-24"
        >
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
              {stats.map((stat, index) => (
                <motion.div
                  key={stat.label}
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  className="text-center p-6 bg-gray-50 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
                >
                  <div className="flex justify-center mb-4">{stat.icon}</div>
                  <div className="text-3xl font-bold text-gray-800 mb-2">
                    {stat.value}
                  </div>
                  <div className="text-sm text-gray-600">{stat.label}</div>
                </motion.div>
              ))}
            </div>
          </div>
        </motion.section> */}

        {/* Mission and Vision Section */}
        <motion.section
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="container mx-auto px-4 py-24 relative"
        >
          <div className="flex flex-col gap-16">
            {/* Vision Section */}
            <motion.div
              initial={{ x: -100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
              className="flex flex-col md:flex-row items-start gap-8"
            >
              <div className="relative mb-8 md:mb-0 text-center">
                <div className="w-32 h-32 bg-blue-100 rounded-full flex items-center justify-center shadow-lg">
                  <Eye width={40} height={40} className="text-blue-600" />
                </div>
                <h2 className="text-lg font-semibold text-gray-800">
                  Our Vision
                </h2>
              </div>
              <div className="flex-1 mt-4">
                <p className="text-gray-700 text-lg leading-relaxed mb-4">
                  To be the catalyst for technological innovation and creative
                  problem-solving in Vietnam's education landscape. We envision
                  a future where every student is empowered with cutting-edge
                  skills, ready to shape the digital world.
                </p>
                <p className="text-gray-700 text-lg leading-relaxed">
                  Our goal is to bridge the gap between traditional education
                  and the rapidly evolving tech industry, creating a new
                  generation of adaptable, forward-thinking professionals.
                </p>
              </div>
            </motion.div>

            {/* Divider */}
            <div className="w-full h-px bg-gray-200"></div>

            {/* Mission Section */}
            <motion.div
              initial={{ x: 100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
              className="flex flex-col md:flex-row items-start gap-8"
            >
              <div className="flex-1 order-2 md:order-1">
                <p className="text-gray-700 text-lg leading-relaxed mb-4">
                  Our mission is to provide world-class, industry-aligned
                  technology education that prepares students for the careers of
                  tomorrow. We're committed to fostering an environment of
                  continuous learning, innovation, and collaboration.
                </p>
                <p className="text-gray-700 text-lg leading-relaxed">
                  Through partnerships with leading tech companies and a
                  curriculum that evolves with industry trends, we ensure our
                  students are always at the cutting edge of technological
                  advancement.
                </p>
              </div>
              <div className="relative order-1 md:order-2 mb-8 md:mb-0">
                <div className="w-32 h-32 bg-green-100 rounded-full flex items-center justify-center shadow-lg">
                  <Target width={40} height={40} className="text-green-600" />
                </div>
                <h2 className="text-xl font-semibold text-gray-800 text-center">
                  Our Mission
                </h2>
              </div>
            </motion.div>

            {/* Divider */}
            <div className="w-full h-px bg-gray-200"></div>

            {/* Core Values Section */}
            <motion.section
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
              className="flex flex-col md:flex-row items-start gap-8"
            >
              <div className="relative mb-8 md:mb-0">
                <div className="w-32 h-32 bg-purple-100 rounded-full flex items-center justify-center shadow-lg">
                  <Atom width={40} height={40} className="text-purple-600" />
                </div>
                <h2 className="text-xl font-semibold text-gray-800 text-center">
                  Our Core Values
                </h2>
              </div>
              <div className="flex-1 mt-4">
                <ul className="space-y-4">
                  <li className="flex items-start">
                    <Cpu className="w-6 h-6 text-blue-500 mr-2 mt-1" />
                    <div>
                      <h3 className="font-semibold text-gray-800">
                        Innovation
                      </h3>
                      <p className="text-gray-600">
                        Embracing cutting-edge technologies and methodologies to
                        stay ahead of the curve.
                      </p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <Users className="w-6 h-6 text-green-500 mr-2 mt-1" />
                    <div>
                      <h3 className="font-semibold text-gray-800">
                        Collaboration
                      </h3>
                      <p className="text-gray-600">
                        Fostering a community of learners and industry partners
                        to drive collective growth.
                      </p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <TrendingUp className="w-6 h-6 text-purple-500 mr-2 mt-1" />
                    <div>
                      <h3 className="font-semibold text-gray-800">
                        Adaptability
                      </h3>
                      <p className="text-gray-600">
                        Preparing students to thrive in an ever-changing
                        technological landscape.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </motion.section>
          </div>
        </motion.section>

        {/* Partners Section */}
        <PartnersSection />

        {/* CTA Section */}
        <motion.section
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="bg-gradient-to-r from-blue-600 to-yellow-400 text-white-a700 py-24 relative overflow-hidden"
        >
          <div className="container mx-auto px-4 text-center relative z-10">
            <div className="max-w-3xl mx-auto">
              <motion.h2
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="text-3xl font-bold mb-6"
              >
                Ready to Lead the Tech Revolution?
              </motion.h2>
              <motion.p
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className="mb-8 text-xl text-gray-100"
              >
                Join MetaSquare and become part of Vietnam's next generation of
                tech innovators and leaders.
              </motion.p>
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5, delay: 0.4 }}
                className="space-x-4"
              >
                <Button
                  variant="outline"
                  size="lg"
                  className="border-white-a700 text-white-a700 hover:bg-white-a700 hover:text-blue-600"
                  onClick={() => navigate("/contact-us")}
                >
                  Contact Us
                </Button>
              </motion.div>
            </div>
          </div>
          {/* Animated background */}
          <div className="absolute inset-0">
            {[...Array(20)].map((_, i) => (
              <motion.div
                key={i}
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 0.1, scale: 1 }}
                transition={{
                  duration: 2,
                  delay: i * 0.1,
                  repeat: Infinity,
                  repeatType: "reverse",
                }}
                className="absolute rounded-full bg-white-a700"
                style={{
                  left: `${Math.random() * 100}%`,
                  top: `${Math.random() * 100}%`,
                  width: `${Math.random() * 50 + 10}px`,
                  height: `${Math.random() * 50 + 10}px`,
                }}
              />
            ))}
          </div>
        </motion.section>

        <FAQSection />
      </div>
    </Layout>
  );
}
