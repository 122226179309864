import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { Img } from "../../components/ui/Img";
import Layout from "../layout";

interface SectionProps {
  title: string;
  description: string;
  imageSrc: string;
  backgroundSrc: string;
  isHovered: boolean;
  onHover: () => void;
  onLeave: () => void;
  onClick: () => void;
  isMobile: boolean;
}

export default function ProductsPage() {
  const [hoveredSection, setHoveredSection] = useState<
    "robotics" | "drones" | null
  >(null);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  return (
    <Layout>
      <div className="flex flex-col md:flex-row min-h-screen">
        <Section
          title="Robotic AI"
          description="Cutting-edge artificial intelligence for advanced robotics"
          imageSrc="/Images/megaton/MEGATON.png"
          backgroundSrc="/Images/AI.jpeg"
          isHovered={hoveredSection === "robotics"}
          onHover={() => setHoveredSection("robotics")}
          onLeave={() => setHoveredSection(null)}
          onClick={() => navigate("/products/robotics")}
          isMobile={isMobile}
        />
        <Section
          title="Drones"
          description="High-performance drones for various applications"
          imageSrc="/Images/Drones/drone1.png"
          backgroundSrc="/Images/drone.png"
          isHovered={hoveredSection === "drones"}
          onHover={() => setHoveredSection("drones")}
          onLeave={() => setHoveredSection(null)}
          onClick={() => navigate("/products/drones")}
          isMobile={isMobile}
        />
      </div>
    </Layout>
  );
}

const Section: React.FC<SectionProps> = ({
  title,
  description,
  imageSrc,
  backgroundSrc,
  isHovered,
  onHover,
  onLeave,
  onClick,
  isMobile,
}) => {
  return (
    <motion.div
      className={`relative flex-1 flex flex-col items-center justify-center overflow-hidden cursor-pointer ${
        isMobile ? "min-h-[50vh] pt-28" : "min-h-screen p-8"
      }`}
      onMouseEnter={isMobile ? undefined : onHover}
      onMouseLeave={isMobile ? undefined : onLeave}
      onClick={onClick}
    >
      {/* Background image */}
      <Img
        src={backgroundSrc}
        alt={`${title} background`}
        className={`absolute inset-0 w-full h-full object-cover transition-all duration-500 ease-in-out ${
          isHovered && !isMobile ? "opacity-60" : "opacity-60"
        }`}
        style={{
          filter: isHovered && !isMobile ? "blur(8px)" : "none",
        }}
      />
      {/* Glow effect using the actual image */}
      {!isMobile && (
        <div
          className="absolute inset-0 bg-cover bg-center transition-opacity duration-500 ease-in-out"
          style={{
            backgroundImage: `url(${backgroundSrc})`,
            opacity: isHovered ? 0.3 : 0,
            filter: "blur(20px) brightness(1.5)",
          }}
        />
      )}
      {/* Overlay */}
      <div className="absolute inset-0 bg-black/20" />
      {/* Content */}
      <motion.div
        className="relative z-10 text-center flex flex-col items-center w-full max-w-lg mx-auto"
        animate={{ scale: isHovered && !isMobile ? 1.05 : 1 }}
        transition={{ duration: 0.3 }}
      >
        <h2
          className={`text-4xl font-bold mb-4 transition-all duration-300 ${
            isHovered && !isMobile ? "text-white-a700" : "text-white"
          }`}
          style={{
            textShadow:
              isHovered && !isMobile
                ? "0 0 8px rgba(255, 255, 255, 0.7), 0 0 15px rgba(255, 255, 255, 0.7)"
                : "0 2px 4px rgba(0,0,0,0.5)",
          }}
        >
          {title}
        </h2>
        <p
          className={`text-lg mb-8 max-w-md transition-all duration-300 ${
            isHovered && !isMobile ? "text-white-a700" : "text-white"
          }`}
          style={{
            textShadow:
              isHovered && !isMobile
                ? "0 0 8px rgba(255, 255, 255, 0.7), 0 0 15px rgba(255, 255, 255, 0.7)"
                : "0 1px 2px rgba(0,0,0,0.5)",
          }}
        >
          {description}
        </p>
        <div className="relative w-64 h-64 mb-8">
          <Img
            src={imageSrc}
            alt={title}
            className="rounded-lg shadow-lg object-cover w-full h-full transition-all duration-500 ease-in-out"
          />
        </div>
        <motion.button
          className="px-6 py-3 bg-white text-black rounded-full font-semibold hover:bg-opacity-90 transition-colors"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Learn More
        </motion.button>
      </motion.div>
    </motion.div>
  );
};
