import React from "react";
import { motion } from "framer-motion";
import { ChevronDown } from "lucide-react";

interface ScrollDownAnimationProps {
  onClick: () => void;
}

const ScrollDownAnimation: React.FC<ScrollDownAnimationProps> = ({
  onClick,
}) => {
  return (
    <motion.div
      className="pt-20 bottom-8 left-1/2 transform -translate-x-1/4 cursor-pointer text-center"
      animate={{ y: [0, -10, 0] }}
      transition={{ repeat: Infinity, duration: 1.5 }}
      onClick={onClick}
    >
      <div className="flex flex-col items-center justify-center font-lexend font-bold text-white-a700 mb-2">
        <span className="text-xl">Click here to explore more</span>
      </div>
      <ChevronDown className="w-12 h-12 text-white-a700 mx-auto" />
    </motion.div>
  );
};

export default ScrollDownAnimation;
